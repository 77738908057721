<script>
import {find} from "@/domain/marketplace/services/produtos"
import SlLoading from "components/layout/components/Loading"

export default {
  name: "Produto",
  components: {SlLoading},
  data() {
    return {
      loading: true,
      produto: null
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    load(id) {
      this.loading = true
      find(id, true)
          .then(response => {
            this.produto = response.data
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>

<template>
  <div class="produto-container">
    <div v-if="!loading" class="produto">
      <div @click="$router.push({name: 'marketplace.dashboard'})" class="close-btn"><i class="fa-solid fa-times" /> </div>
      <div class="h-full full-width flex items-center justify-center column">
        <div><h3>Em breve</h3></div>
        <div class="m-t">A contratação em nosso marketplace <strong class="m-l-xs">ficará  disponível a partir do dia 19 de abril de 2024</strong>.</div>
      </div>
    </div>
    <div v-else class="produto loading">
      <sl-loading />
      <div class="m-t">Carregando informações sobre o produto..</div>
    </div>
  </div>
</template>
